import React, {useRef} from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import emailjs from '@emailjs/browser';

const ContactForm = () => {

    const form = useRef();
const [name, setName] = React.useState('');
const [email, setEmail] = React.useState('');
const [number, setNumber] = React.useState('');
const [message, setMessage] = React.useState('');
const [success, setSuccess] = React.useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_q3yv1bi', 'template_54t8jty', form.current, 'RorJ68mxxuCi4df6q')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        clearForm();
    };

const clearForm = () => {
    setName('');
    setEmail('');
    setNumber('');
    setMessage('');
    setSuccess(true);
}

    return (
        <section className="contact-area pb-3">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Get in Touch
                    </span>
                    <h2>Ready to Get Started?</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" ref={form} onSubmit={sendEmail}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Your name" onChange={event => setName(event.target.value)}
                                                   value={name}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" required placeholder="Your email address" onChange={event => setEmail(event.target.value)}
                                                   value={email}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" className="form-control" required placeholder="Your phone number" onChange={event => setNumber(event.target.value)}
                                                   value={number}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message..." onChange={event => setMessage(event.target.value)}
                                                      value={message}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i> 
                                            Send Message <span></span>
                                        </button>
                                        {
                                            success? <div
                                                className="alert alert-success p-3 mt-3 d-flex justify-content-between align-items-center">The
                                                email has been sent successfully
                                                <small role="button" className="pe-auto" onClick={()=> setSuccess(false)}><b>X</b></small>
                                            </div> : null
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm