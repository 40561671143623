import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pb-2">
            <div className="container">
                <div className="row">
                    {/*<div className="col-lg-4 col-md-6">*/}
                    {/*    <div className="contact-info-box">*/}
                    {/*        <div className="back-icon">*/}
                    {/*            <i className='bx bx-map'></i>*/}
                    {/*        </div>*/}
                    {/*        <div className="icon">*/}
                    {/*            <i className='bx bx-map'></i>*/}
                    {/*        </div>*/}
                    {/*        <h3>Our Address</h3>*/}
                    {/*        <p>175 5th Ave, New York, NY 10010, United States</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-lg-6 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:+48794532276">(+48) 794 532 276</a></p>
                            <p>E-mail: <a href="mailto:contact@red-pot-studio.com">contact@red-pot-studio.com</a></p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 09:00 - 20:00</p>
                            <p>Sunday & Saturday: Closed</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo